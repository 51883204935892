<template>
  <div>
    <div class="filter-panel">
      <input v-model="queryParams.search" class="cs-input" placeholder="搜索位置/备注" type="text">
      <CSSelect style="margin-right: 30px;">
        <select v-model="queryParams.state">
          <option value="">位置状态不限</option>
          <option :value="1">启用</option>
          <option :value="2">禁用</option>
          <option :value="3">作废</option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="getLocationList()">查询</button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button class="btn btn-primary sticky-right" @click="openAddLocationDialog">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              位置
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th style="min-width: 144px;">添加时间</th>
            <th>位置</th>
            <th>位置状态</th>
            <th>iBeaconUUID</th>
            <th>关联事物</th>
            <th>登记记录</th>
            <th>备注</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="locationList.length > 0">
            <tr v-for="location in locationList" :key="location.id">
              <td style="min-width: 144px;">{{ location.createTime }}</td>
              <td>{{ location | splicingLocation }}</td>
              <td>{{ locationState[location.state] || '-' }}</td>
              <td>
                                <span v-if="location.beaconId" class="allow-click"
                                      @click="lookBeaconId(location.beaconId)">查看</span>
                <template v-else>-</template>
              </td>
              <td>
                                <span v-if="location.associateCount" class="allow-click"
                                      @click="$router.push({name: 'relatedTransaction', params: {id: location.id}})">查看</span>
                <template v-else>-</template>
              </td>
              <td>
                                <span v-if="location.registerCount" class="allow-click"
                                      @click="lookCheckInLog(location.id)">查看</span>
                <template v-else>-</template>
              </td>
              <td>
                                <span v-if="location.comment" class="allow-click"
                                      @click="lookComment(location.comment)">查看</span>
                <template v-else>-</template>
              </td>
              <td>{{ location.createUserName || '-' }}</td>
              <td>
                <div v-if="location.state !== 3" class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="min-width: 76px;width: 100px;">
                    <template v-if="location.state === 2">
                      <li @click="changeLocationState(location.id, 1)"><a
                          style="width: 100%;">启用</a></li>
                      <li @click="changeLocationState(location.id, 3)"><a
                          style="width: 100%;">作废</a></li>
                    </template>
                    <li @click="changeLocationState(location.id, 2)"><a v-if="location.state === 1"
                                                                        style="width: 100%;">禁用</a>
                    </li>
                    <li v-if="location.qrcode"
                        @click="lookQrCode(commonImgOssDomain + location.qrcode)"><a
                        style="width: 100%;">查看二维码</a></li>
                    <li v-if="location.qrcode"><a :href="downloadFileOssDomain + location.qrcode" style="width: 100%;"
                                                  target="_blank">下载二维码</a>
                    </li>
                  </ul>
                </div>
                <template v-else>-</template>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination/>
    </div>
    <ViewImageModal/>
    <CSDialog :dialog-visible="addLocationDialogVisible" dialog-confirm-btn-text="保存" dialog-title="添加位置"
              dialog-width="967px" @onClose="addLocationDialogVisible = false" @onConfirm="addLocation"
    >
      <template v-slot:dialog-content>
        <div class="location-dialog-content">
          <div class="location-form-item">
            <div class="location-form-item-label top">位置</div>
            <div class="location-form-item-content" style="margin-top: -1px;">
              <CSRadio v-model="addLocationParams.type" :items="{1: '楼栋', 2: '空旷区域（园区内的道路/广场/大门...等）'}"
                       inner-width="12px"
                       style="margin-bottom: 24px;"
                       width="20px"></CSRadio>
              <div v-show="addLocationParams.type === 1">
                <span style="display: inline-block;vertical-align: middle;margin-right: 5px;">楼栋</span>
                <CSSelect height="40px" i-width="30px" style="margin-right: 35px;">
                  <select v-model="addLocationParams.buildingId" style="width: 170px;color: #999999"
                          @change="changeBuilding">
                    <option value="">请选择</option>
                    <option v-for="building in buildingList" :key="building.id"
                            :value="building.id">
                      {{ building.name }}
                    </option>
                  </select>
                </CSSelect>
                <span style="display: inline-block;vertical-align: middle;margin-right: 5px;">楼层</span>
                <CSSelect height="40px" i-width="30px">
                  <select v-model="addLocationParams.floor" style="width: 170px;color: #999999">
                    <option value="">请选择</option>
                    <option v-for="floor in floorSpace"
                            :key="floor" :value="floor"
                    >
                      {{ floor }}
                    </option>
                  </select>
                </CSSelect>
              </div>
              <div>
                <span style="display: inline-block;vertical-align: middle;margin-right: 5px;">具体位置</span>
                <input v-model="addLocationParams.specificLocation"
                       :placeholder="`例：${addLocationParams.type === 1 ? '东南角男士卫生间' : '园区东南门'}`"
                       style="width: 400px;"
                       type="text"
                       @input="searchSimilarAddress">
              </div>
              <div v-if="similarAddressHtml !== ''">
                <div class="tip">有相似的位置，请勿重复添加。</div>
                <div class="similar-address" v-html="similarAddressHtml"></div>
              </div>
            </div>
          </div>
          <div class="location-form-item">
            <div class="location-form-item-label">iBeaconUUID</div>
            <div class="location-form-item-content">
              <input v-model="addLocationParams.beaconId" placeholder="非必填" style="width: 400px;"
                     type="text"
                     @focus="onBeaconIdInputFocus">
            </div>
          </div>
          <div class="location-form-item">
            <div class="location-form-item-label top">备注</div>
            <div class="location-form-item-content">
                            <textarea v-model="addLocationParams.comment" maxlength="200" placeholder="限200个字，非必填"
                                      type="text"></textarea>
              <div class="tip">
                <svg
                    aria-hidden="true"
                    class="icon"
                >
                  <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                确定添加后，将无法修改信息，请仔细认真检查所填信息。
              </div>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination";
import {
  addLocationUrl,
  changeLocationStateUrl,
  commonImgOssDomain,
  downloadFileOssDomain,
  queryBuildingUrl,
  queryLocationUrl,
  querySimilarLocationUrl
} from '@/requestUrl';
import CSRadio from "@/components/common/CSRadio";
import CSTable from "@/components/common/CSTable";
import ViewImageModal from "@/components/ViewImageModal";

let timer;
export default {
  name: "LocationManage",
  created() {
    window.addEventListener("keydown", this.getLocationListDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.getLocationListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getLocationListDown);
  },
  components: {
    ViewImageModal,
    CSTable,
    CSSelect,
    Pagination,
    CSDialog,
    CSRadio,
  },
  data() {
    return {
      commonImgOssDomain,
      addLocationDialogVisible: false,
      filterHeight: 0,
      downloadFileOssDomain,
      locationState: {
        1: '启用',
        2: '禁用',
        3: '作废',
      },
      queryParams: {
        state: '',
        search: '',
      },
      locationList: [],
      buildingList: [],
      floorSpace: [],
      similarAddressHtml: '',  // 相似的地址
      similarAddressArr: [], // 相似地址的数组
      timer: null,
      addLocationParams: {
        type: 1,
        specificLocation: '',
        buildingId: '',
        buildingName: '',
        floor: '',
        beaconId: '',
        comment: '',
      },
      isClick: true,
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  watch: {
    'addLocationParams.type': function (val) {
      if (val === 2) {
        this.addLocationParams.buildingName = '';
        this.addLocationParams.buildingId = '';
        this.addLocationParams.floor = '';
        this.addLocationParams.specificLocation = '';
      }
    }
  },
  filters: {
    // 拼接完整地址
    splicingLocation(location) {
      let floor = '';
      if (location.floor) {
        floor = location.floor + '层';
      }
      return `${location.buildingName || ''}${floor}${location.specificLocation}`
    },
  },
  mounted() {
    this.getLocationList();
    this.$vc.on(this.$route.path, 'pagination_page', 'event', this.paginationPage);
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  methods: {
    lookQrCode(qrCodeUrl) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", {
        title: '查看二维码',
        photos: [qrCodeUrl]
      });
    },
    //监听分页组件处理函数
    paginationPage(pageNo) {
      this.pageParams.pageNo = pageNo;
      this.getLocationList(pageNo);
    },
    getLocationListDown(e) {
      if (e.keyCode == 13) {
        this.getLocationList();
      }
    },
    /**
     * 查看登记记录
     * @param {Number} id 位置id
     * */
    lookCheckInLog(id) {
      this.$router.push({
        name: 'locationCheckInLog',
        params: {id}
      })
    },
    /**
     * 查看备注
     * @param {String} comment 备注
     * */
    lookComment(comment) {
      this.$CSDialog.alert({
        title: '查看备注',
        message: comment,
      })
    },
    /**
     * 查看iBeaconId
     * @param {String} beaconId
     * */
    lookBeaconId(beaconId) {
      this.$CSDialog.alert({
        title: 'iBeaconUUID',
        message: beaconId,
      })
    },
    // 搜索相似地址
    searchSimilarAddress() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getSimilarAddress();
      }, 500);
    },
    // 获取相似位置
    getSimilarAddress() {
      const {specificLocation} = this.addLocationParams;
      if (specificLocation === '') {
        this.similarAddressHtml = '';
        return;
      }
      this.$fly.post(querySimilarLocationUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        specificLocation,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            let addressHtml = '',
                similarAddressArr = [],
                locationResStr = res.data.map((location, index) => {
                  return `${location.buildingName || ''}${location.floor ? location.floor + '层' : ''}${location.specificLocation}`
                }).toString();
            specificLocation.split(',').forEach(char => {
              locationResStr = locationResStr.replaceAll(char, `<span style="color: #ff5a5a">${char}</span>`);
            })
            if (locationResStr && locationResStr !== '') {
              locationResStr.split(',').forEach((location, index) => {
                const originLocation = res.data[index];
                similarAddressArr.push(`${originLocation.buildingName || ''}${originLocation.floor ? originLocation.floor + '层' : ''}${originLocation.specificLocation}`);
                addressHtml += `<p style="font-size: 20px;line-height: 1;margin-bottom: ${index === res.data.length ? '0' : '10px'};">${location}</p>`
              })
            }
            this.similarAddressArr = similarAddressArr;
            this.similarAddressHtml = addressHtml;
          })
    },
    /**
     * 修改选中的楼栋
     * @param {Object} e
     * */
    changeBuilding(e) {
      if (e.target.selectedIndex > 0) {
        const building = this.buildingList[e.target.selectedIndex - 1];
        this.addLocationParams.buildingName = building.name;
        let floorSpace = [];
        if (building.undergroundFloor == null) {
          for (let i = 1; i <= building.aboveGroundFloor; i++) {
            if (i !== 0) {
              floorSpace.push(i);
            }
          }
        } else {
          for (let i = -building.undergroundFloor; i <= building.aboveGroundFloor; i++) {
            if (i !== 0) {
              floorSpace.push(i);
            }
          }
        }
        this.floorSpace = floorSpace;
        return;
      }
      this.floorSpace = [];
    },
    // 获取楼栋
    queryBuildings() {
      this.$fly.post(queryBuildingUrl, {
        state: 1,
        regionCode: this.$vc.getCurrentRegion().code,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.buildingList = res.data.datas;
          })
    },
    // 监听beacon输入框获取到焦点
    onBeaconIdInputFocus() {
      if (this.addLocationParams.beaconId === '') {
        this.addLocationParams.beaconId = this.creatUUID();
      }
    },
    // 创建uuid
    creatUUID() {
      let s = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      return s.join("");
    },
    openAddLocationDialog() {
      this.addLocationDialogVisible = true;
      this.similarAddressHtml = '';
      this.queryBuildings();
      this.addLocationParams = {
        type: 1,
        specificLocation: '',
        buildingId: '',
        buildingName: '',
        floor: '',
        beaconId: '',
        comment: '',
      };
    },
    /**
     * 修改位置状态
     * @param {Number} id 位置id
     * @param {Number} state 位置状态 详见：locationState
     * */
    changeLocationState(id, state) {
      this.$CSDialog.confirm({
        title: '提示',
        message: `确定${this.locationState[state]}吗?`,
        onConfirm: () => {
          this.$fly.post(changeLocationStateUrl, {
            id,
            state,
            regionCode: this.$vc.getCurrentRegion().code,
          })
              .then(res => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.$vc.toast(`${this.locationState[state]}成功`);
                this.getLocationList();
              })
        }
      })
    },
    /**
     * 获取位置列表
     * @param {Number} pageNo 当前页码
     * @param {Number} pageSize 每次查询条数
     * */
    getLocationList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly.post(queryLocationUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize,
        ...this.queryParams,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, 'pagination', 'init', {
                total: res.data.total,
                pageSize,
                currentPage: pageNo,
              })
            }
            this.locationList = res.data.datas;
          })
    },
    verifyLocationInfo() {
      const config = {
        specificLocation: [{
          limit: "required",
          errInfo: '请输入位置'
        }]
      };
      if (this.addLocationParams.type === 1) {
        config.buildingId = [{limit: "required", errInfo: '请选择楼栋'}];
        config.buildingName = [{limit: "required", errInfo: '请选择楼栋'}];
        config.floor = [{limit: "required", errInfo: '请选择楼层'}];
      }
      return this.$vc.check(this.addLocationParams, config);
    },

    // 添加位置
    addLocation() {
      if (!this.verifyLocationInfo()) {
        return;
      }

      if (this.similarAddressArr.includes(`${this.addLocationParams.buildingName || ''}${this.addLocationParams.floor ? this.addLocationParams.floor + '层' : ''}${this.addLocationParams.specificLocation}`)) {
        this.$vc.message('请勿重复添加同样的地点');
        return;
      }

      this.$CSDialog.confirm({
        title: '提示',
        message: '保存后将无法修改信息，确定保存吗？',
        confirmBtnText: '保存',
        onConfirm: async () => {
          if (this.isClick) {
            this.isClick = false;
            await this.$fly.post(addLocationUrl, {
              regionCode: this.$vc.getCurrentRegion().code,
              ...this.addLocationParams
            })
                .then(res => {
                  if (res.code !== 0) {
                    this.isClick = true;
                    return;
                  }
                  this.$vc.toast('添加成功');
                  this.$CSDialog.instance.closeDialog();
                  this.addLocationDialogVisible = false;
                  this.getLocationList();
                })
            this.isClick = true;
          }
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.location-dialog-content
  padding 30px
  font-size 24px
  color #000

  .location-form-item
    font-size 0

    &:not(:last-of-type)
      margin-bottom 27px

    &-label
      width 146px
      text-align right
      margin-right 40px
      font-size 24px
      display inline-block
      line-height 1
      vertical-align middle

      &.top
        vertical-align top

    &-content
      font-size 24px
      display inline-block
      vertical-align middle
      max-width 720px

      & > div
        &:not(:last-of-type)
          margin-bottom 12px

      .similar-address
        width 400px
        height 200px
        background #FFFFFF
        border-radius 4px
        border 1px solid #999999
        padding 10px
        margin-top 10px
        overflow-y auto

        p
          font-size 20px
          line-height 1
          margin-bottom 0

          &:not(:last-of-type)
            margin-bottom 10px

          span
            line-height 1
            color var(--theme-color)

      input[type="text"]
        height 40px
        border-radius 4px
        border 1px solid #979797
        padding 0 10px
        vertical-align middle

      select
        padding 6px

      textarea
        width 720px
        height 200px
        background #F0F0F0
        border-radius 4px
        border none
        outline none
        padding 6px 10px
        resize none

      .tip
        margin-top 10px
        font-size 20px
        line-height 1
        color #999
</style>
